import React from "react"
import Layout from "../components/layout"
import { Container } from "react-bootstrap"
import Seo from "../components/Seo/seo"
export default function Legal() {
  return (
    <Layout>
      <Seo title="Legal Information" />
      <Container>
        <h1 className="my-5 text-uppercase">Legal Information</h1>
        <strong>Publisher & responsible for the content</strong>
        <p>Natalia Pacheco</p>
        <strong>Address</strong>
	<p>Am Husholz 6</p>
	<p>50968 Köln</p>
        <p>(+49) (0)177 745 13 32 or (+49) (0)157 80 74 61 82</p>
        <p>info@latinsalsa.de</p>
        <strong>Office hours</strong>
        <p>Mon, Thu, Fri 9 am - 12 pm</p>
        <strong>Web design, logo design & Graphic Design</strong>
        <p>
          <a href="https://www.inkfs.com" target="_blank" rel="noreferrer">
            Jay Fischer, Ink and Feather Design Studios
          </a>
        </p>
        <strong>Photography</strong>
        <p>
          <a href="https://www.inkfs.com" target="_blank" rel="noreferrer">
            Jay Fischer
          </a>
        </p>
      </Container>
    </Layout>
  )
}
